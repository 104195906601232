import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/homepage/homepage/src/layouts/pages.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Was ich spannend finde`}</h1>
    <h2>{`Grundeinkommen`}</h2>
    <p>{`Was würdest du tun, wenn du dir keine Sorgen um dein Einkommen machen müsstest? Das ist die essentielle Frage hinter dem Bedingungslosen Grundeinkommen. Und wenn man die weiter spinnt, kommt man zu erstaunlichen Antworten.`}</p>
    <p>{`Ich beschäftige mich mindestens seit meiner Zeit bei der `}<a parentName="p" {...{
        "href": "https://bge-initiative-leipzig.blogspot.com/"
      }}>{`Initiative Grundeinkommen Leipzig`}</a>{` und meiner `}<a parentName="p" {...{
        "href": "https://welfairness.eu/2017/02/27/umfrageauswertung-negative-einkommensteuer-waere-akzeptiert/"
      }}>{`Abschlussarbeit im Soziologiestudium`}</a>{` mit dem Grundeinkommen. Aus meiner Sicht könnten etliche soziale Probleme damit gelöst werden:`}</p>
    <ul>
      <li parentName="ul">{`Absolute Armut wäre verhindert, denn jeder hätte ein Existenzminimum.`}</li>
      <li parentName="ul">{`Der größte Teil der Sozialstaatsbürokratie wäre nicht mehr nötig, weil auch Bürgergeld, Wohngeld, Bafög und vieles andere überflüssig wäre.`}</li>
      <li parentName="ul">{`Kriminalität und sogar Umweltverschmutzung könnten abnehmen, da durch weniger finanzielle Zwänge, Menschen bessere Entscheidungen treffen könnten.`}</li>
    </ul>
    <h2>{`Energie und Klimaschutz`}</h2>
    <p>{`Von Strom selbst erzeugen, Smart Home und klimaneutralem Hausbau über Energiewende, Smart Grids und Elektromobilität bis zu Industrieprozessen auf Basis von Wasserstoff: Finde ich alles spannend.`}</p>
    <h2>{`Science Fiction`}</h2>
    <p>{`Ich lese und sehe gern Science Fiction, weil dieser am besten packende Geschichten mit meinem Interesse an völlig neuen Ideen und Gedanken verbindet. Hier eine Empfehlungsliste:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://de.wikipedia.org/wiki/Die_drei_Sonnen"
          }}><strong parentName="a">{`Trisolaris`}</strong>{`-Trilogie von Liu Cixin`}</a>{`: Sticht durch eine neue Lösung des `}<a parentName="p" {...{
            "href": "https://de.wikipedia.org/wiki/Fermi-Paradoxon"
          }}>{`Fermi-Paradoxons`}</a>{` hervor und bietet auch darüber hinaus viele gedankenöffnende Ideen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://de.wikipedia.org/wiki/The_Expanse_(Romanreihe)"
          }}><strong parentName="a">{`The Expanse`}</strong>{` von Daniel James Abraham und Ty Franck`}</a>{`: Zeigt wie die Menschheit in wenigen hundert Jahren leben könnte, wenn wir zusammen mit der technischen nicht auch eine soziale Weiterentwicklung vorantreiben.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://de.wikipedia.org/wiki/Die_Kultur"
          }}><strong parentName="a">{`Culture`}</strong>{`-Serie von Iain M. Banks`}</a>{`: Eine galaxieumspannende Zivilisation ohne jeglichen Mangel. Wahnsinnig spannend aus Grundeinkommens- und Technikperspektive.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://de.wikipedia.org/wiki/Phillip_P._Peterson#Bibliografie"
          }}><strong parentName="a">{`Paradox`}</strong>{`-Trilogie von Phillip P. Peterson`}</a>{`: Was ist, wenn unser Sternenhimmel etwas ganz anderes ist, als wir uns vorstellen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/Patrick_Lee_(novelist)#Travis_Chase_series"
          }}><strong parentName="a">{`Travis Chase`}</strong>{` Serie von Patrick Lee`}</a>{`: Geheimtipp und Mix aus vielen Sci-Fi-Genres. Packend von der ersten bis zur letzten Seite.`}</p>
      </li>
    </ol>
    <h2>{`Web-Entwicklung`}</h2>
    <p>{`Ich interessiere mich für alle neuen Entwicklungen in dem Bereich und probiere viel aus: SvelteKit, React, Gatsby, Next.js, Storybook, Contentful um nur einiges zu nennen.`}</p>
    <p>{`Software as a Service (SaaS) ist für mich dabei nicht wegzudenken. Vergangene Geschäftsmodelle haben vom einmaligen und dann bei Updates wiederkehrenden Verkäufen gelebt. Das ist aus meiner Sicht weder zeitgemäß noch nachhaltig.`}</p>
    <p>{`Wer als Unternehmen mehrere verschiedene Versionen und Instanzen bei Kunden parallel pflegen muss, kommt kaum noch dazu, seine Software weiterzuentwickeln. Wer schnell voran kommen möchte, braucht Nutzer, die alle auf dem gleichen Stand der Software sind.`}</p>
    <p>{`Unter anderem die Baubranche ist interessant, weil es hier besonders viel zu tun gibt. Selbst große Unternehmen hantieren bei essentiellen Geschäftsprozessen immer noch mit viel Papier bzw. unter Einsatz von händischen Datenübertragungsschritten.`}</p>
    <h2>{`Leben in Brandenburg `}<a parentName="h2" {...{
        "href": "https://www.berlin.de/tourismus/berlinerisch/5095489-4528094-jwd.html"
      }}>{`jwd`}</a></h2>
    <p>{`Hier in Brandenburg ist's schön und nach Berlin ist's nicht weit, wenn man mal unbedingt rein muss. Und jeden Tag in einen anderen See springen zu können - auch wenn ich's nicht jeden Tag mache - ist ein Luxus, auf den ich nicht mehr verzichten möchte. Und unsere Kinder sind Wasserratten.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      